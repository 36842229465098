import { getTitleByQuestionItem } from '@/components/QuestionItem';
import { EQuestionItemSubType, EQuestionItemType, IQuestionResText } from '@/interface/survey';
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api';
import './index.less';


/**
 * 问卷问题-文本域
 */
export default defineComponent({
  name: 'QuestionTextArea',
  props: {
    onChange: Function as PropType<(value: IQuestionResText, oldValue: IQuestionResText) => void>,
    onFocus: Function as PropType<() => void>,
    onBlur: Function as PropType<() => void>,
    index: {
      type: Number,
      required: true,
    },
    value: {
      type: Object as PropType<IQuestionResText>,
      required: true,
    }
  },
  setup(props, ctx) {
    const { value, index } = toRefs(props);
    const title = computed(() => {
      return getTitleByQuestionItem(value.value.model, index.value)
    })
    const ele = computed(() => {
      const item = value.value;
      if (item.type !== EQuestionItemType.Text) {
        return null;
      }
      const { sub_type } = item.model;
      // 如果是多行文本则显示4行，反之1行
      const lineItems = sub_type === EQuestionItemSubType.TEXTAREA ? 4 : 1;
      return <div class='task-question-item'>
        {
          item.model.title
            ? [
              index.value !== 0 ? <div class="divider"></div> : null,
              <div class='title'>{title.value}</div>,
            ]
            : null
        }
        <t-textarea
          class="cus-textarea"
          style={{
            // 没有标题的情况，需要加大margin-top，没有标题margin-top为16，默认.textarea的margin-top为8px
            marginTop: item.model.title ? undefined : '16px',
          }}
          placeholder={item.model.description}
          value={item.value}
          autosize={{ minRows: lineItems, maxRows: lineItems }}
          maxlength={1000}
          onChange={(val: string) => {
            const res: IQuestionResText = {
              type: EQuestionItemType.Text,
              model: item.model,
              value: val,
            };
            ctx.emit('change', res, item);
          }}
          onFocus={() => ctx.emit('focus')}
          onBlur={() => ctx.emit('blur')}
        />
      </div>;
    });

    return {
      ele,
    };
  },
  render() {
    return <div>
      {this.ele}
    </div>;
  },
});
