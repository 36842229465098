import { Col, Row } from '@/components/Grid';
import ImageViewer from '@/components/ImageViewer';
import { getTitleByQuestionItem } from '@/components/QuestionItem';
import { EAspectRatio, EQuestionItemSubType, EQuestionItemType, IQuestionOptionStyle, IQuestionResOption } from '@/interface/survey';
import { useDebounceFn, useRef } from '@/utils/composition-helper';
import { isNumber } from '@/utils/number-ext';
import { PropType, computed, defineComponent, toRefs } from '@vue/composition-api';
import _ from 'lodash';
import { IQuestionItemValidate } from '..';
import CusCheckbox from '../CusCheckbox';
import CusRadio from '../CusRadio';
import { getMaxTipsEle, getOptionTypeEle } from '../util';
import './index.less';

/**
 * 问卷问题-选项题（图片类型）
 */
export default defineComponent({
  name: 'QuestionOptionImage',
  props: {
    onChange: Function as PropType<(value: IQuestionResOption, oldValue: IQuestionResOption) => void>,
    index: {
      type: Number,
      required: true,
    },
    value: {
      type: Object as PropType<IQuestionResOption>,
      required: true,
    }
  },
  setup(props, ctx) {
    const { value, index } = toRefs(props);
    const [showError, setShowError] = useRef(false);
    const [timer, setTimer] = useRef();
    const docId = computed(() => `option-image-doc-id-${index.value}`);
    // 跳转至错误的题目处
    const go2ErroHtmlrElement = useDebounceFn(() => {
      clearTimeout(timer.value as any);
      setShowError(true);
      setTimeout(() => {
        document.querySelector(`#${docId.value}`)?.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      }, 50);
      setTimer(setTimeout(() => {
        setShowError(false);
      }, 2000));
    }, 10);
    const title = computed(() => {
      return getTitleByQuestionItem(value.value.model, index.value)
    })
    const ele = computed(() => {
      const item = value.value;
      if (item.type !== EQuestionItemType.Option) {
        return null;
      }
      const { style, options, sub_type } = item.model;
      // 过滤被删除的选项
      const displayOptions = options.filter(c => c.is_deleted !== true);
      const styleObj: IQuestionOptionStyle = style
        ? JSON.parse(style.replace(/: "/g, ':"'))
        : {
          image_aspect_ratio: EAspectRatio.ONE_TO_ONE,
          line_items: '3',
        };
      // 1: 1，渲染时，每行展示两个选项
      // 4: 3，渲染时，每行展示两个选项
      // 16: 9，渲染时，每行展示1个选项
      // 3: 4，渲染时，每行展示两个选项
      // 9: 16，渲染时，每行展示两个选项
      // 在设置图片比例的同事会去修改line_items的个数，所以这里使用line_items即可
      const { line_items, max, image_aspect_ratio } = styleObj;
      const aspectRatioArr = image_aspect_ratio?.split(':') ?? [];
      const aspect_ratio_style = {
        aspectRatio: `${aspectRatioArr[0]} / ${aspectRatioArr[1]}`,
      };
      const span = 12 / parseInt(line_items);
      return <div class={`task-question-option-image-item`} id={docId.value}>
        {
          index.value !== 0
            ? <div class="divider"></div>
            : null
        }
        <div class='title'>
          <Row gutter={8}>
            <Col class='fs-16px fw-5'>{title.value}</Col>
            <Col>{getOptionTypeEle(item.model)}</Col>
            {
              sub_type === EQuestionItemSubType.IMAGE_CHECKBOX && showError.value
                ? <Col><span class='error-max-tag cus-shake fs-14px'>最多选{max}项</span></Col>
                : null
            }
          </Row>
        </div>
        {/* 单选非必填情况则不需要显示tips */}
        {
          sub_type === EQuestionItemSubType.IMAGE_RADIO && !item.model.required
            ? null
            : getMaxTipsEle(max, item.model.required)
        }
        <div class='multi-container-pop'>
          <Row gutter={[24, 24]} align='stretch'>
            {
              displayOptions.map((option) => {
                const index = item.value.findIndex((k) => k.id === option.id);
                const selected = index !== -1;
                return <Col span={span} class='flex flex-stretch'>
                  <div
                    class='option-image-item'
                  >
                    <div
                      class='image-container-aspect-ratio'
                      style={aspect_ratio_style}
                    >
                      <div class='w-100-p h-100-p uploaded-panel'>
                        <ImageViewer
                          imageUrl={option.image}
                          renderContent={() => <div style={{ backgroundImage: `url(${option.image})` }} class='responsive-image-aspect-ratio' />}
                        />
                      </div>
                    </div>
                    <div class='w-100-p h-100-p relative'>
                      <Row justify='center' align='middle' class='w-100-p pt-10px pb-10px pl-12px pr-12px'>
                        <Col>
                          {
                            sub_type === EQuestionItemSubType.IMAGE_CHECKBOX
                              ? <CusCheckbox
                                checked={selected}
                                onChange={() => {
                                  let newOptions = _.cloneDeep(item.value);
                                  if (selected) {
                                    newOptions.splice(index, 1);
                                  } else {
                                    // 如果最多可选为1的情况，逻辑改为点击切换
                                    if (max?.toString?.() === '1' || option.exclusive || (newOptions.length > 0 && newOptions[0].exclusive && !option.exclusive)) {
                                      newOptions = [option];
                                      const res: IQuestionResOption = {
                                        ...item,
                                        value: newOptions,
                                      };
                                      ctx.emit('change', res, item);
                                      return;
                                    }
                                    // 如果有最大可选，并且个数和已选个数相等时，弹出错误提示
                                    if (max !== undefined && isNumber(max) && Number(max) <= newOptions.length) {
                                      go2ErroHtmlrElement();
                                      return;
                                    }
                                    newOptions.push(option)
                                  }
                                  const res: IQuestionResOption = {
                                    ...item,
                                    value: newOptions,
                                  };
                                  ctx.emit('change', res, item);
                                }}
                              >
                                <span class='c-666 font-weight-normal'>{option.text}</span>
                              </CusCheckbox>
                              : <CusRadio
                                checked={selected}
                                onChange={() => {
                                  const res: IQuestionResOption = {
                                    ...item,
                                    value: [option],
                                  };
                                  ctx.emit('change', res, item);
                                }}
                              >
                                <span class='c-666 font-weight-normal'>{option.text}</span>
                              </CusRadio>
                          }
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>;
              })
            }
          </Row>
        </div>
      </div>;
    });
    async function validate(): Promise<IQuestionItemValidate> {
      return { result: true };
    }
    function validateRule(): IQuestionItemValidate {
      return { result: true };
    }
    
    return {
      ele,
      validate,
      validateRule,
    };
  },
  render() {
    return <div class={`survey-question-option question-option-image`}>
      {this.ele}
    </div>;
  },
});
