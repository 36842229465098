import { Col, Row } from "@/components/Grid";
import { getTitleByQuestionItem } from '@/components/QuestionItem';
import { EQuestionItemType, IQuestionResStar, IQuestionStarStyle } from "@/interface/survey";
import { useRef } from "@/utils/composition-helper";
import { computed, defineComponent, PropType, toRefs } from "@vue/composition-api";
import "./index.less";

/**
 * 问卷问题-量表题
 */
export default defineComponent({
  name: "QuestionStar",
  props: {
    onChange: Function as PropType<(value: IQuestionResStar, oldValue: IQuestionResStar) => void>,
    index: {
      type: Number,
      required: true,
    },
    value: {
      type: Object as PropType<IQuestionResStar>,
      required: true,
    },
  },
  setup(props, ctx) {
    const { value, index } = toRefs(props);
    const [starSelected, setStarSelected] = useRef(-1);
    const starType = computed(() => {
      const styleObj: IQuestionStarStyle = value.value.model.style
        ? JSON.parse(value.value.model.style.replace(/: "/g, ':"'))
        : {
            star_question_render_style: "emoji",
          };
      return styleObj.star_question_render_style;
    });
    const title = computed(() => {
      return getTitleByQuestionItem(value.value.model, index.value)
    })
    const optionEles = computed(() => {
      const item = value.value;
      if (item.type !== EQuestionItemType.Star) {
        return [];
      }
      const options = item.model.options ?? [];
      return options.map((option) => {
        return (
          <Col class={`score-item ${item.value?.[0]?.id === option.id ? "selected" : ""}`} flex={1}>
            <div
              onClick={() => {
                const res: IQuestionResStar = {
                  ...item,
                  value: [option],
                };
                ctx.emit("change", res, item);
              }}>
              {option.id}
            </div>
          </Col>
        );
      });
    });
    const ele = computed(() => {
      const item = value.value;
      const options = item.model.options ?? [];
      // 文本类型量表题
      if (starType.value === "text") {
        return [
          <div class="mt-16px fs-16px fw-5">
            {item.model.description ? <div class="mt-16px fs-12px c-666">{item.model.description}</div> : null}
          </div>,
          <div class="flex-div score-img mt-16px">
            <img src={require("@/assets/NPS/1.svg")} width="11" height="11" alt="" />
            <span class="fs-s" style="margin-left: 4px">
              {options?.[0]?.text}
            </span>
            <span class="fs-s" style="margin-left: auto; margin-right: 4px">
              {options?.[options.length - 1]?.text}
            </span>
            <img src={require("@/assets/NPS/10.svg")} width="11" height="11" alt="" />
          </div>,
          <div class="mt-8px">
            <Row gutter={8}>{optionEles.value}</Row>
          </div>,
        ];
      }
      const emojiOptionEles = options.map((option) => {
        const selected = item.value?.[0]?.id === option.id;
        const imgPath = selected ? `${option.id}_selected` : option.id;
        return (
          <Col flex={1}>
            <div
              onClick={() => {
                const res: IQuestionResStar = {
                  ...item,
                  value: [option],
                };
                ctx.emit("change", res, item);
              }}>
              <Row justify="center">
                <Col>
                  <img src={require(`@/assets/CSAT/${imgPath}.png`)} width="30" height="30" alt="" />
                </Col>
              </Row>
              <Row justify="center" class="mt-4px">
                <Col>
                  <span class="fs-12px c-assist-8">{option.text}</span>
                </Col>
              </Row>
            </div>
          </Col>
        );
      });
      const starOptionEles = options.map((option) => {
        const selected = item.value?.[0]?.id >= option.id;
        const imgPath = selected ? "star-fill" : "star";
        return (
          <Col flex={1}>
            <div
              class="star-item"
              onClick={() => {
                const res: IQuestionResStar = {
                  ...item,
                  value: [option],
                };
                ctx.emit("change", res, item);
              }}>
              <img width="30" height="30" src={require(`@/assets/svgs/${imgPath}.svg`)} />
            </div>
          </Col>
        );
      });
      const getOptionEle = (type: "text" | "star" | "emoji") => {
        if (type === "emoji") {
          return emojiOptionEles;
        } else if (type === "star") {
          return starOptionEles;
        } else return emojiOptionEles;
      };
      // 表情类型
      return [
        <div class="img-div" style="margin-top: 17px">
          <Row>{getOptionEle(starType.value)}</Row>
        </div>,
      ];
    });

    return {
      optionEles,
      ele,
      title
    };
  },
  render() {
    const { value: item, index } = this.$props;
    return (
      <div class="task-question-item client-question-star">
        {index !== 0 ? <div class="divider"></div> : null}
        <div class="title">{this.title}</div>
        {this.ele}
      </div>
    );
  },
});
