import { EQuestionItemSubType, EQuestionItemType, IQuestionItem, IQuestionResItem, IQuestionResMatrix, IQuestionResOption, IQuestionResSensitiveInput, IQuestionResStar, IQuestionResText, IQuestionSurveyModel } from '@/interface/survey';
import { handleRichTitle } from '@/utils/survey-helper';
import { PropType, computed, defineComponent, toRefs } from '@vue/composition-api';
import _ from 'lodash';
import './index.less';
import QuestionMatrix from './QuestionMatrix';
import QuestionOption from './QuestionOption';
import QuestionOptionImage from './QuestionOptionImage';
import QuestionSensitive from './QuestionSensitive';
import QuestionStar from './QuestionStar';
import QuestionTextArea from './QuestionTextArea';

export function getTitleByQuestionItem(question: IQuestionItem, index: number) {
  const { required, title, rich_text_title } = question;
  const paddedIndex = _.padStart((index + 1).toString(), 2, "0");
  const customTitle = () => {
    if (rich_text_title === undefined || rich_text_title === '') {
      return <span>{title}</span>
    } else return <span domPropsInnerHTML={handleRichTitle(rich_text_title, title)}></span>;
  }
  if (required) {
    paddedIndex
    return <span><span class='c-error-1'>*</span><span class='mr-4px'>{paddedIndex}</span>{customTitle()}</span>;
  }
  return <span><span class='mr-4px'>{paddedIndex}</span>{customTitle()}</span>;
}

export interface IQuestionItemValidate {
  result: boolean;
  message?: string;
};

/**
 * 问卷问题实例，用于校验该题目是否通过校验
 */
export abstract class QuestionItemIns {
  public abstract validate?: () => Promise<IQuestionItemValidate>;
}

/**
 * 问卷问题
 */
export default defineComponent({
  name: 'QuestionItem',
  props: {
    onChange: Function as PropType<(value: IQuestionResItem, oldValue: IQuestionResItem) => void>,
    onFocus: Function as PropType<() => void>,
    onBlur: Function as PropType<() => void>,
    index: {
      type: Number,
      required: true,
    },
    value: {
      type: Object as PropType<IQuestionResItem>,
      required: true,
    },
    surveyModel: {
      type: Object as PropType<IQuestionSurveyModel>,
      required: true,
    },
    answerMaps: {
      type: Object as PropType<Record<string, IQuestionResItem>>,
      required: true,
    },
  },
  setup(props, ctx) {
    const { value, index, surveyModel, answerMaps, } = toRefs(props);
    const ele = computed(() => {
      const item = value.value;
      if (!item) {
        return null;
      }
      const { model } = item;
      const { sub_type } = model;
      switch (item.type) {
        case EQuestionItemType.Star:
          return <QuestionStar
            index={index.value}
            value={item as IQuestionResStar}
            onChange={(value, oldValue) => {
              ctx.emit('change', value, oldValue);
            }}
          />;
        case EQuestionItemType.Option: {
          if (sub_type === EQuestionItemSubType.IMAGE_RADIO || sub_type === EQuestionItemSubType.IMAGE_CHECKBOX) {
            return <QuestionOptionImage
              index={index.value}
              value={item as IQuestionResOption}
              onChange={(value, oldValue) => {
                ctx.emit('change', value, oldValue);
              }}
            />;
          }
          return <QuestionOption
            index={index.value}
            value={item as IQuestionResOption}
            onChange={(value, oldValue) => {
              ctx.emit('change', value, oldValue);
            }}
          />;
        }
        case EQuestionItemType.Matrix:
          return <QuestionMatrix
            index={index.value}
            surveyModel={surveyModel.value}
            answerMaps={answerMaps.value}
            value={item as IQuestionResMatrix}
            onChange={(value, oldValue) => {
              ctx.emit('change', value, oldValue);
            }}
          />;
        case EQuestionItemType.Text:
          return <QuestionTextArea
            index={index.value}
            value={item as IQuestionResText}
            onChange={(value, oldValue) => {
              ctx.emit('change', value, oldValue);
            }}
            onFocus={() => ctx.emit('focus')}
            onBlur={() => ctx.emit('blur')}
          />;
        case EQuestionItemType.SensitiveInput:
          return <QuestionSensitive
            index={index.value}
            value={item as IQuestionResSensitiveInput}
            onChange={(value, oldValue) => {
              ctx.emit('change', value, oldValue);
            }}
            onFocus={() => ctx.emit('focus')}
            onBlur={() => ctx.emit('blur')}
          />
        default:
          return null;
      }
    });
    return {
      ele,
    };
  },
  render() {
    return <div>
      {this.ele}
    </div>;
  },
});
