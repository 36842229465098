import { Col, Row } from '@/components/Grid';
import { getTitleByQuestionItem } from '@/components/QuestionItem';
import QuestionOptionText from '@/components/QuestionOptionText';
import { EQuestionItemSubType, EQuestionItemType, IQuestionOptionStyle, IQuestionResOption } from '@/interface/survey';
import { useDebounceFn, useRef } from '@/utils/composition-helper';
import { isNumber } from '@/utils/number-ext';
import { PropType, computed, defineComponent, toRefs } from '@vue/composition-api';
import _ from 'lodash';
import CusCheckbox from '../CusCheckbox';
import CusRadio from '../CusRadio';
import { getMaxTipsEle, getOptionTypeEle } from '../util';
import './index.less';


/**
 * 问卷问题-选项题
 */
export default defineComponent({
  name: 'QuestionOption',
  props: {
    onChange: Function as PropType<(value: IQuestionResOption, oldValue: IQuestionResOption) => void>,
    index: {
      type: Number,
      required: true,
    },
    value: {
      type: Object as PropType<IQuestionResOption>,
      required: true,
    }
  },
  setup(props, ctx) {
    const { value, index } = toRefs(props);
    const [showError, setShowError] = useRef(false);
    const [timer, setTimer] = useRef();
    console.log(value.value, index.value)
    const docId = computed(() => `option-max-${index.value}`);
    // 跳转至错误的题目处
    const go2ErroHtmlrElement = useDebounceFn(() => {
      clearTimeout(timer.value as any);
      setShowError(true);
      setTimeout(() => {
        document.querySelector(`#${docId.value}`)?.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      }, 50);
      setTimer(setTimeout(() => {
        setShowError(false);
      }, 2000));
    }, 10);
    const title = computed(() => {
      return getTitleByQuestionItem(value.value.model, index.value)
    })
    const ele = computed(() => {
      const item = value.value;
      if (item.type !== EQuestionItemType.Option) {
        return null;
      }
      const { style, sub_type } = item.model;
      const styleObj: IQuestionOptionStyle = style ? JSON.parse(style.replace(/: "/g, ':"')) : {
        line_items: '3',
      };
      const { line_items, max } = styleObj;
      if (line_items === '1') {
        return <div class='task-question-item' id={docId.value}>
          {
            index.value !== 0
              ? <div class="divider"></div>
              : null
          }
          <div class='title'>
            <Row gutter={8}>
              <Col class='fs-16px fw-5'>{title.value}</Col>
              <Col>{getOptionTypeEle(item.model)}</Col>
              {
                max !== undefined && showError.value
                  ? <Col><span class='error-max-tag cus-shake fs-14px'>最多选{max}项</span></Col>
                  : null
              }
            </Row>
          </div>
          {/* 单选非必填情况则不需要显示tips */}
          {
            sub_type === EQuestionItemSubType.RADIO && !item.model.required
              ? null
              : getMaxTipsEle(max, item.model.required)
          }
          <div class='multi-container-text'>
            {
              item.model.options?.map(option => {
                const index = item.value.findIndex((k) => k.id === option.id);
                const selected = index !== -1;
                return <div
                  class='multi-item'
                >
                  {
                    max?.toString() === '1'
                      ? <CusRadio
                        checked={selected}
                        onChange={() => {
                          const res: IQuestionResOption = {
                            ...item,
                            value: [option],
                          };
                          ctx.emit('change', res, item);
                        }}
                      >
                        <QuestionOptionText
                          key={option.text}
                          value={option.text}
                        />
                      </CusRadio>
                      : <CusCheckbox
                        checked={selected}
                        onChange={() => {
                          let newOptions = _.cloneDeep(item.value);
                          if (selected) {
                            newOptions.splice(index, 1);
                          } else {
                            // 如果最多可选为1的情况，逻辑改为点击切换
                            if (max?.toString?.() === '1' || option.exclusive || (newOptions.length > 0 && newOptions[0].exclusive && !option.exclusive)) {
                              newOptions = [option];
                              const res: IQuestionResOption = {
                                ...item,
                                value: newOptions,
                              };
                              ctx.emit('change', res, item);
                              return;
                            }
                            // 如果有最大可选，并且个数和已选个数相等时，弹出错误提示
                            if (max !== undefined && isNumber(max) && Number(max) <= newOptions.length) {
                              go2ErroHtmlrElement();
                              return;
                            }
                            newOptions.push(option)
                          }
                          const res: IQuestionResOption = {
                            ...item,
                            value: newOptions,
                          };
                          ctx.emit('change', res, item);
                        }}
                      >
                        <QuestionOptionText
                          key={option.text}
                          value={option.text}
                        />
                      </CusCheckbox>
                  }
                </div>;
              })
            }
          </div>
        </div>
      }
      const span = 12 / parseInt(line_items);
      return <div class='task-question-item' id={docId.value}>
        {
          index.value !== 0
            ? <div class="divider"></div>
            : null
        }
        <div class='title'>
          <Row gutter={8}>
            <Col class='fs-16px fw-5'>{title.value}</Col>
            <Col>{getOptionTypeEle(item.model)}</Col>
            {
              max !== undefined && showError.value
                ? <Col><span class='error-max-tag cus-shake fs-14px'>最多选{max}项</span></Col>
                : null
            }
          </Row>
        </div>
        {
          sub_type === EQuestionItemSubType.RADIO && !item.model.required
            ? null
            : getMaxTipsEle(max, item.model.required)
        }
        <div class='multi-container-pop'>
          <Row gutter={[8, 8]} align='stretch'>
            {
              item.model.options?.map((option) => {
                const index = item.value.findIndex((k) => k.id === option.id);
                const selected = index !== -1;
                return <Col averageCount={parseInt(line_items)} class='flex flex-stretch'>
                  <div
                    class={`multi-item w-100-p ${selected ? 'selected' : ''}`}
                    onClick={() => {
                      let newOptions = _.cloneDeep(item.value);
                      if (selected) {
                        newOptions.splice(index, 1);
                      } else {
                        // 如果最多可选为1的情况，逻辑改为点击切换
                        if (max?.toString?.() === '1' || option.exclusive || (newOptions.length > 0 && newOptions[0].exclusive && !option.exclusive)) {
                          newOptions = [option];
                          const res: IQuestionResOption = {
                            ...item,
                            value: newOptions,
                          };
                          ctx.emit('change', res, item);
                          return;
                        }
                        // 如果有最大可选，并且个数和已选个数相等时，弹出错误提示
                        if (max !== undefined && isNumber(max) && Number(max) <= newOptions.length) {
                          go2ErroHtmlrElement();
                          return;
                        }
                        newOptions.push(option)
                      }
                      const res: IQuestionResOption = {
                        ...item,
                        value: newOptions,
                      };
                      ctx.emit('change', res, item);
                    }}
                  >
                    <Row justify='center' align='middle' class='h-100-p w-100-p'>
                      <Col>
                        <QuestionOptionText
                          key={option.text}
                          value={option.text}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>;
              })
            }
          </Row>
        </div>
      </div>;
    });

    return {
      ele,
    };
  },
  render() {
    return <div>
      {this.ele}
    </div>;
  },
});
