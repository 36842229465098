import { Col, Row } from '@/components/Grid';
import { getTitleByQuestionItem } from '@/components/QuestionItem';
import { EQuestionItemType, IQuestionResSensitiveInput, IQuestionSensitiveInput } from '@/interface/survey';
import { useRef } from '@/utils/composition-helper';
import { PropType, computed, defineComponent, toRefs } from '@vue/composition-api';
import CusCheckbox from '../CusCheckbox';
import './index.less';

export default defineComponent({
  name: 'QuestionSensitive',
  props: {
    index: {
      type: Number,
      required: true,
    },
    onChange: Function as PropType<(value: IQuestionSensitiveInput, oldValue: IQuestionSensitiveInput) => void>,
    onFocus: Function as PropType<() => void>,
    onBlur: Function as PropType<() => void>,
    value: {
      type: Object as PropType<IQuestionResSensitiveInput>,
      required: true,
    }
  },

  setup(props, ctx) {
    const { index, value } = toRefs(props);
    const [protolVisible, setProtolVisible] = useRef(false);
    const title = computed(() => {
      return getTitleByQuestionItem(value.value.model, index.value)
    })
    const ele = computed(() => {
      const item = value.value;
      if (item.type !== EQuestionItemType.SensitiveInput) {
        return null;
      }
      let showProtol = { license: 1 };
      try {
        showProtol = JSON.parse(item.model.style);
      } catch (error) {
        console.log(error);
      }

      return <div class='task-question-item task-question-sensitive'>
        {
          index.value !== 0
            ? <div class="divider"></div>
            : null
        }
        <div class='title'>{title.value}</div>
        <t-input
          class='t-input-text'
          placeholder={item.model.description}
          value={item.value}
          maxlength={100}
          onChange={(val: string) => {
            const res: IQuestionResSensitiveInput = {
              type: EQuestionItemType.SensitiveInput,
              model: item.model,
              value: val,
              confirm: item.confirm,
            };
            ctx.emit('change', res, item);
          }}
          onFocus={() => ctx.emit('focus')}
          onBlur={() => ctx.emit('blur')}
        />
        {
          showProtol.license === 1 && <Row class='mt-8px' align='middle'>
            <Col class='line-height'>
              <CusCheckbox
                checked={item.confirm}
                onChange={(checked: boolean) => {
                  const res: IQuestionResSensitiveInput = {
                    type: EQuestionItemType.SensitiveInput,
                    model: item.model,
                    value: item.value,
                    confirm: checked,
                  };
                  ctx.emit('change', res, item);
                }}
              />
            </Col>
            <Col>我已阅读并同意</Col>
            <Col><a class='cus-link' href='javascript:void(0);' onClick={() => setProtolVisible(true)}>《填答者协议》</a></Col>
            <Col>和</Col>
            <Col><a class='cus-link' href='javascript:void(0);' onClick={() => window.open('https://privacy.qq.com/document/preview/2491347092a64d7fa00cbc2bf68fbbbb')}>《腾讯问卷隐私政策》</a></Col>
          </Row>
        }
      </div>;
    });

    return {
      ele,
      protolVisible,
      setProtolVisible,
    }
  },

  render() {
    return <div class='sensitive-item-wrap'>
      {this.ele}
      <t-dialog visible={this.protolVisible} header={false} footer={false} onClose={() => this.setProtolVisible(false)}>
        <img class='inline-block' src={require('@/assets/protol.png')} width='100%' />
      </t-dialog>
    </div>
  }
})
