import { EQuestionItemType, ERichTitleModelTags, IQuestionItem, IQuestionMatrix, IQuestionOption, IQuestionOptionItem, IQuestionOptionStyle, IQuestionResItem, IRichTitleModel } from '@/interface/survey';
import _ from 'lodash';

/**
 * 随机打乱数据元素
 * @param array 
 * @example const arr: { id: string; text: string; is_deleted: boolean }[] = [
  { "id": "1", "text": "行标题1", "is_deleted": false },
  { "id": "2", "text": "行标题2", "is_deleted": true },
  { "id": "3", "text": "行标题3", "is_deleted": false },
  { "id": "4", "text": "行标题4", "is_deleted": false }
];
const shuffledArr = shuffleArray(arr);
 * @returns 
 */
function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

/**
 * 处理转换option类型的问题（比如处理option的随机，固定选项）
 * @param question 
 */
export function convert2SurveyOption(question: IQuestionOption): IQuestionOption {
  // 如果是多选题，并且已勾选随机选项，将多选题的选项打乱顺序（is_random为空的也打乱）
  if (question.is_random || question.is_random === undefined) {
    const currOptions = _.cloneDeep(question.options).filter(c => c.is_deleted !== true);
    // 获取固定的选项索引数组
    const fixedIndexArr = currOptions.reduce((res: number[], option, index) => {
      if (option.is_fixed) {
        return [...res, index];
      }
      return res;
    }, []);
    const randomIndexArr = currOptions.reduce((res: number[], option, index) => {
      if (!option.is_fixed) {
        return [...res, index];
      }
      return res;
    }, []);
    const newOptions: IQuestionOptionItem[] = [];
    // 现将固定的选项，按照旧有的位置填充至newOptions
    fixedIndexArr.forEach(index => {
      newOptions[index] = currOptions[index];
    });
    // 打乱需要随机的索引
    const newRandomIndexArr = _.sortBy(randomIndexArr, () => _.random(0, randomIndexArr.length));
    randomIndexArr.forEach((oldIndex, index) => {
      const newIndex = newRandomIndexArr[index];
      // 将旧的model(currOptions[oldIndex])赋值到新的options中
      newOptions[newIndex] = currOptions[oldIndex];
    });
    const res: IQuestionOption = {
      ...question,
      options: newOptions,
    };
    return res;
  }
  return question;
}

/**
 * 处理转换matrix类型的问题（比如处理matrix的随机，固定选项）
 * @param question 
 */
export function convert2SurveyMatrix(question: IQuestionMatrix): IQuestionMatrix {
  // 如果是多选题，并且已勾选随机选项，将多选题的选项打乱顺序（is_random为空的也打乱）
  if (question.is_random || question.is_random === undefined) {
    const subTitles = _.cloneDeep(question.sub_titles ?? []).filter(c => c.is_deleted !== true);
    const sub_titles = shuffleArray(subTitles);
    const res: IQuestionMatrix = {
      ...question,
      sub_titles,
    };
    return res;
  }
  return question;
}

/**
 * 处理默认数据返回的questions（比如处理option的随机，固定选项）
 * @param questions 
 * @returns 
 */
export function convert2SurveyQuestions(questions: IQuestionItem[]): IQuestionItem[] {
  const newQuestions: IQuestionItem[] = questions.map(c => {
    if (c.type === EQuestionItemType.Option) {
      return convert2SurveyOption(c);
    }
    if (c.type === EQuestionItemType.Matrix) {
      return convert2SurveyMatrix(c);
    }
    return c;
  });
  return newQuestions;
}

/**
 * 兼容ios系统下tux sdk上报h5提交事件
 */
export function onH5SubmitAnswer() {
  // 在安卓中仍旧使用
  window.tux?.onH5SubmitAnswer?.();
  // 如果是ios则不需要isHalfDialog等逻辑，直接调用ios的jsb
  if (window.webkit?.messageHandlers?.handleH5SubmitAnswer?.postMessage) {
    window.webkit?.messageHandlers?.handleH5SubmitAnswer?.postMessage?.('');
    console.log('handleH5SubmitAnswer:', window?.webkit?.messageHandlers?.handleH5SubmitAnswer?.postMessage);
    return;
  }
}

export interface IDisplayTreeNode {
  id: string;
  indexPath: number[];
  children: IDisplayTreeNode[];
}

/**
 * 递归获取树形结构列表
 * @param displayNode
 * @param answerMaps
 * @returns
 */
export function getDisplayTreeNodes(
  displayNode: IDisplayTreeNode,
  answerMaps: Record<string, IQuestionResItem>,
): IDisplayTreeNode[] {
  const { id, indexPath } = displayNode;
  const info = answerMaps[id];
  if (info && info.type !== EQuestionItemType.Text) {
    const { type, value, model } = info;
    // 永远只显示结果的第一个匹配的displays，旧逻辑
    let children =
      type === EQuestionItemType.Option || type === EQuestionItemType.Star
        ? value?.[0]?.displays?.map((k, index) => {
          const model: IDisplayTreeNode = {
            id: k,
            indexPath: [...indexPath, index],
            children: [],
          };
          return model;
        })
        : [];
    if (type === EQuestionItemType.Option) {
      const { style } = model;

      const styleObj: IQuestionOptionStyle = style
        ? JSON.parse(style.replace(/: "/g, ':"'))
        : {
          line_items: '3',
        };
      const { multiple } = styleObj;
      // 如果有multiple属性，则表示不互斥（第二道题假设为多选，有选项2A, 2B）
      // 当勾选2A展示3A和3B
      // 当勾选2B展示3C和3D
      // 勾选2A和2B的同时展示[2A, 2B, 3A, 3B, 3C, 3D]
      if (multiple) {
        children = value.reduce((res: IDisplayTreeNode[], item) => {
          const arr =
            item?.displays?.map((k, index) => {
              const currIndex = res.length + index;
              const model: IDisplayTreeNode = {
                id: k,
                indexPath: [...indexPath, currIndex],
                children: [],
              };
              return model;
            }) ?? [];
          return [...res, ...arr];
        }, []);
      }
    }
    displayNode.children =
      children?.reduce((res: IDisplayTreeNode[], child) => {
        return [...res, ...getDisplayTreeNodes(child, answerMaps)];
      }, []) ?? [];
    return [displayNode];
  }
  return [displayNode];
}

/**
 * 问题是否存在选项
 */
export function hasOptions(question: IQuestionItem) {
  switch (question.type) {
    case EQuestionItemType.Matrix:
    case EQuestionItemType.Star:
    case EQuestionItemType.Option:
      return true;
    default:
      return false;
  }
}

const getStyleStr = (arr: Array<{ key: string; value: string }>) => {
  let str = '';
  arr.forEach(item => {
    str += `${item.key}: ${item.value};`;
  });
  return str;
};

export const handleRichTitle = (
  richTextTitle: string,
  title: string
) => {
  if (richTextTitle === '')
    return `<span>${title}</span>`
  const richTitle: Array<IRichTitleModel> = JSON.parse(richTextTitle)
  if (richTitle.length > 0) {
    const recursion = (item: IRichTitleModel) => {
      if (item.tag === ERichTitleModelTags.TEXT) {
        return item.content;
      } else {
        let str = '';
        const styleStr = getStyleStr(item.style ?? []);
        str += `<${item.tag}`;
        (item.attr ?? []).forEach(p => {
          str += ` ${p.key}="${p.value}"`;
        });
        str += ` style="${styleStr}">`;
        (item.children ?? []).forEach(child => {
          str += recursion(child);
        });
        str += `</${item.tag}>`;
        return str;
      }
    };
    let res = '';
    richTitle.forEach(item => {
      res += recursion(item);
    });
    return res;
  } else {
    return `<span>${title}</span>`;
  }
};
